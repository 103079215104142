.banner-image {
    width: 100%;
    height: 100vh; /* 50% of viewport height */
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .banner-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .image-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    opacity: 0;
    transition: opacity 0.8s ease; /* Smooth transition for fading overlay */
  }
  
  .banner-content {
    position: absolute;
    top: 35%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    text-align: center;
    color: #fff;
    padding: 20px;
    z-index: 1;
  }

  @media (max-width: 600px) { /* Adjust this value based on your breakpoints */
    .banner-content {
      top: 45%;
      left: 0;
      transform: translate(0, -50%);
    }
  }
  
  .nav-icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .prev-button, .next-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 24px; /* Adjust icon size as needed */
    padding: 8px; /* Adjust padding as needed for larger clickable area */
    border-radius: 50%; /* Make the buttons circular */
  }
  
  .prev-button:hover, .next-button:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darken the background on hover */
  }
  