.box-container {
    display: flex;
    flex-direction: column;
    padding: 35px 0px;
    width: 100%;
}

@media screen and (min-width:1380px) {
    .box-container {
        flex-direction: row
    }
}

.box-item {
    position: relative;
    -webkit-backface-visibility: hidden;
    width: 415px;
    margin-bottom: 35px;
    max-width: 100%;
}

.flip-box {
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 1000px;
    -webkit-perspective: 1000px;
}

.flip-box-front,
.flip-box-back {
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    min-height: 475px;
    -ms-transition: transform 0.9s cubic-bezier(.4, .2, .2, 1) !important;
    transition: transform 0.9s cubic-bezier(.4, .2, .2, 1) !important;
    -webkit-transition: transform 0.9s cubic-bezier(.4, .2, .2, 1) !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    color: #fff !important;
}

.flip-box-front {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-box:hover .flip-box-front {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-box:hover .flip-box-back {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-box .inner {
    /* position: absolute; */
    left: 0;
    width: 100%;
    padding: 15px;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    /* transform: translateY(-50%) translateZ(15px) scale(.94);
    -webkit-transform: translateY(-50%) translateZ(15px) scale(.94);
    -ms-transform: translateY(-50%) translateZ(15px) scale(.94);
    top: 50%; */
}

.flip-box-header {
    font-size: 34px !important;
}

.flip-box-p {
    font-size: 20px !important;
    line-height: 1.5em !important;
    position: absolute !important;
    transform: translateY(-50%) translateZ(15px) !important;
    -webkit-transform: translateY(-50%) translateZ(15px) !important;
    -ms-transform: translateY(-50%) translateZ(15px) !important;
    top: 45% !important;
}

.flip-box-img {
    margin-top: 25px;
}