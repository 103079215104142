.custom-paper {
    padding: 16px;
    height: 100%;
}

.form-input-field {
    font-size: 15px;
    cursor: text;
    max-height: 40px;
    border-radius: 10px;
    border: 1px solid;
    border-color: #D6E2EB;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    transition: border-color 120ms ease-in;
    background-color: rgba(234, 240, 245, 0.4);
}